<template>
  <div class="index_overall">

    <el-container>
<!--      页面头部-->
      <el-header class="index_header">
        <div class="header_image">
          <img :src="require('@/assets/index/index.png')">
        </div>
        <div class="header_text">美家居窗帘软装布艺后台管理系统</div>
        <div class="header_end"> 当前时间： {{nowTime}}</div>
      </el-header>

<!--      页面主体内容-->
      <el-container class="index_main">
<!--        导航栏-->
        <el-aside width="14%">
          <el-menu :default-active="$route.path"
                   class="el-menu-vertical-demo"
                   @open="handleOpen"
                   @close="handleClose"
                   router background-color="#efefef"
                   style="height: 100%">
            <el-menu-item index="/userManage">
              <i class="el-icon-user-solid"></i>
              <span slot="title">用户管理</span>
            </el-menu-item>

            <el-submenu index="2">
              <template slot="title"><i class="el-icon-menu"></i><span>分类管理</span></template>
              <el-menu-item-group>
                <el-menu-item index="/web/system/cateGory"><i class="el-icon-s-unfold"></i>商品分类</el-menu-item>
                <el-menu-item index="/web/system/productRecycling"><i class="el-icon-delete-solid"></i>分类回收站</el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-submenu index="3">
              <template slot="title"><i class="el-icon-s-shop"></i><span>商品管理</span></template>
              <el-menu-item-group>
                <el-menu-item index="/web/system/commodityInformation"><i class="el-icon-s-goods"></i>商品信息</el-menu-item>
                <el-menu-item index="/web/system/commodityRecycling"><i class="el-icon-delete-solid"></i>商品回收站</el-menu-item>
              </el-menu-item-group>
            </el-submenu>

            <el-menu-item index="/orderManagement">
              <i class="el-icon-s-claim"></i>
              <span slot="title">订单管理</span>
            </el-menu-item>

            <el-submenu index="5">
              <template slot="title"><i class="el-icon-s-help"></i><span>系统管理</span></template>
              <el-menu-item-group>
                <el-menu-item index="/web/system/map"><i class="el-icon-s-order"></i>反馈管理</el-menu-item>
                <el-menu-item index="/web/system/carouselMapSystem"><i class="el-icon-picture"></i>轮播图管理</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main><router-view></router-view></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: 'index',

  mounted () {

    this.timeId = setInterval(()=>{
      this.nowTime =dayjs().format('YYYY-MM-DD HH:mm:ss');

    }, 1000);

  },

  data(){
    return{
      timeId: '',
      nowTime: '',
    }
  },

  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    },
  },
}
</script>

<style lang="less" scoped>
@import "src/static/css/index/index.less";
</style>
