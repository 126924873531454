<template>
  <div class="base_overall" style="position: relative">


    <div class="base_header">

      <el-button type="primary" plain class="insert_base_button" v-if="imageNum" @click="insertOrUpdateCarousel(0)">增加图片</el-button>
    </div>

    <div class="base_main">
      <el-table class="base_table" :data="carouselData"
                stripe empty-text="暂时还没有图片哦！" border height="72vh">
        <el-table-column prop="carouselImagePath" label="图片" align="center" :show-overflow-tooltip="true">
          <el-table-column prop="carouselId" v-if="false" label="分类编号" align="center" :show-overflow-tooltip="true"></el-table-column>
          <template slot-scope="scope">
            <div class="block" v-for="fit in fits" :key="fit">
              <el-image
                :fit="fit"
                style="width: 140px; height: 100px"
                :src="imageURL+scope.row.carouselImagePath"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="240">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="insertOrUpdateCarousel(1,scope.row.carouselId)">修改</el-button>
            <el-button type="danger" plain @click="deleteCarouselMapImage(scope.row.carouselId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>


    <div class="base_dialog">
      <el-dialog
        :modal="false"
        :title="text"
        :visible.sync="carouselDialog"
        width="30%"
        @close="CloseCarouselDialog">
        <el-form ref="form" label-width="80px">
          <el-form-item label="轮播图片">
            <input accept="image/*" name="upimage" @change="upload" id="upload_file" type="file">
          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="CloseCarouselDialog">取 消</el-button>
          <el-button v-if="dialogFlag == 0" type="primary" @click="insertOrUpdateCarouselImage">确 定</el-button>
          <el-button v-else type="primary" @click="insertOrUpdateCarouselImage">修 改</el-button>
        </span>
      </el-dialog>
    </div>


  </div>
</template>

<script>

import {
  deleteCarouselImage,
  modifyInsertCarousel,
  selectCarouselMap,
  uploadCarouselImage
} from "@/api/carouselMap/carouselMapSystem";
import {Message} from "element-ui";

export default {
  name: 'carouselMapSystem',

  data() {
    return{
      carouselData: [],
      fits: ['fill'],
      imageURL: 'http://124.70.208.221/api/cateGory/selectImage/',
      imageNum: false,
      carouselDialog: false,
      dialogFlag: 1,
      text:'',
      params: '',
      config: '',
      form: {
        img: ""
      },
      imageName: '',
      carouselId:'',
    }
  },

  mounted () {
    this.selectCarouselMapList();
  },

  methods: {

    //上传图片
    upload (e) {
      console.log(e)
      let file = e.target.files[0];
      let param = new FormData();
      //通过append向form对象添加数据
      param.append("file", file);
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'//必须要指定
        }
      };
      this.params = param;
      console.log(param)
    },

    insertOrUpdateCarouselImage(){

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'//必须要指定
        }
      };
      uploadCarouselImage(this.params,config).then(response => {
        console.log(response)
        this.form.img = response.data;
        this.imageName = response.data.name;
        console.log(this.imageName)
        let obj = {
          carouselId: this.carouselId,
          carouselImagePath : this.imageName
        }
        modifyInsertCarousel(obj).then(res => {
          if(res.data == 0){
            if(this.dialogFlag == 1){
              Message.success("修改成功！");
            }else{
              Message.success("增加成功！");
            }
            this.CloseCarouselDialog();
            this.selectCarouselMapList();
          }
        });
      });

    },

    selectCarouselMapList(){
      selectCarouselMap().then(res=>{
        this.carouselData = res.data;
        console.log(res.data.length)
        if (res.data.length <= 4){
          this.imageNum = true;
        }
      });
    },

    deleteCarouselMapImage(carouselId){
      let data = {
        carouselId : carouselId
      }
      deleteCarouselImage(data).then(res=>{
        if(res.data == 1){
          Message.success("删除成功！");
          this.selectCarouselMapList();
        }
      })
    },

    insertOrUpdateCarousel(flag,id){
      if(flag == 0){
        this.dialogFlag = 0;
        this.text = '新增图片';
      }else{
        this.carouselId = id;
        this.dialogFlag = 1;
        this.text = '修改图片';
      }
      this.carouselDialog = true;
    },

    CloseCarouselDialog(){
      this.carouselDialog = false;
    },


  }


  }
</script>

<style lang="less" scoped>

@import "src/static/css/ClassificationManagement/cateGory.less";
</style>
