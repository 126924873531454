import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/admin/login'
import Index from '@/views/index/index'
import UserManage from '@/views/index/userManage'
import Wel from '@/views/wel'
import CateGory from '@/views/index/classificationManagement/cateGory'
import ProductRecycling from '@/views/index/classificationManagement/productRecycling'
import CommodityInformation from '@/views/index/commodityManagement/commodityInformation'
import CommodityRecycling from '@/views/index/commodityManagement/commodityRecycling'
import OrderManagement from '@/views/index/orderManagement/orderManagement'
import CarouselMapSystem from "@/views/index/carouselMap/carouselMapSystem.vue";
import Map from "@/views/index/carouselMap/map.vue"
import Cookie from 'js-cookie'
Vue.use(VueRouter)

const routes = [
  // {path: '/', redirect: '/login'},
  { path: '/web/system/login', name: 'login', component: Login },
  { path: '/web/system/index', name: 'index', component: Index, redirect: '/web/system/wel',
    children:[
      {path: '/web/system/wel', component: Wel, name: 'wel'},
      {path: '/web/system/userManage', component: UserManage, name: 'userManage'},
      {path: '/web/system/cateGory', name: 'cateGory', component: CateGory },
      {path: '/web/system/productRecycling', name: 'productRecycling', component: ProductRecycling },
      {path: '/web/system/commodityInformation', name: 'commodityInformation', component: CommodityInformation },
      {path: '/web/system/commodityRecycling', name: 'commodityRecycling', component: CommodityRecycling },
      {path: '/web/system/orderManagement', name: 'orderManagement', component: OrderManagement },
      {path: '/web/system/carouselMapSystem', name: 'carouselMapSystem', component: CarouselMapSystem },
      {path: '/web/system/map', name: 'map', component: Map },
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next) => {
  if(to.path === '/web/system/login') return next();
  const tokenStr = Cookie.get('token');
  if(!tokenStr) return next('/');
  next();
});

export default router
