import request from "@/api/request";

export function selectCateGory(data) {
  return request({
    url: '/cateGory/selectCateGory',
    method: 'get',
    params: data
  })
}

export function modifyInsertCateGory(obj) {
  return request({
    url: '/cateGory/modifyInsertCateGory',
    method: 'post',
    data: obj
  })
}

export function offShelf(data) {
  return request({
    url: '/cateGory/offShelf',
    method: 'get',
    params: data
  })
}

export function deleteCateGoryImage(data) {
  return request({
    url: '/cateGory/deleteCateGoryImage',
    method: 'get',
    params: data
  })
}

export function uploadCateGoryImage(obj) {
  return request({
    url: '/cateGory/uploadCateGoryImage',
    method: 'post',
    data: obj
  })
}

export function selectCateGoryName() {
  return request({
    url: '/cateGory/selectCateGoryName',
    method: 'get'
  })
}
