<template>
  <div class="base_overall">
    <!--    商品信息头部区域-->
    <div class="base_header">
      <el-input class="base_search" placeholder="请输入搜索内容" prefix-icon="el-icon-search" v-model="commodityInformationSearch" clearable></el-input>

      <el-select class="base_select" v-model="SelectValue" clearable filterable @change="clickSelect" placeholder="请选择分类">
        <el-option v-for="item in commodityInformationCateGory"
                   :key="item.cateId" :label="item.cateName" :value="item.cateId" ></el-option>
      </el-select>

      <el-button type="primary" icon="el-icon-search" circle style="margin-left: 1%" @click="selectCommodityList"></el-button>
      <el-button type="danger" icon="el-icon-delete" circle @click="clearSearch"></el-button>
      <el-button type="primary" plain class="insert_base_button" @click="insertComm(0)">增加商品</el-button>
    </div>
    <!--    商品信息主体表单区域-->
    <div class="base_main">
      <el-table class="base_table" :data="commodityInformationData" stripe empty-text="暂时还没有商品哦！" border height="72vh">
        <el-table-column type="index" :index="indexMethod" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="commId" label="商品编号" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="commImage" label="商品图片" align="center" :show-overflow-tooltip="true">

          <template slot-scope="scope">
            <div class="block" v-for="fit in fits" :key="fit">
              <el-image
                :fit="fit"
                style="width: 30px; height: 30px"
                :src="imageURL+scope.row.commImage"
              ></el-image>
            </div>
          </template>

        </el-table-column>
        <el-table-column prop="commName" label="商品名称" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="commCateId" v-if="false" label="商品分类" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="commCateName" label="商品分类" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="commPartId" v-if="false" label="商品部件" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="commPartName" label="商品部件" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="commPrice" label="商品价格" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column sortable prop="commSort" label="排序" align="center"></el-table-column>
        <el-table-column sortable prop="commModificationTime" label="修改时间" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" align="center" width="280">
          <template slot-scope="scope">
<!--            <el-button type="success" plain >查看</el-button>-->
            <el-button type="primary" plain @click="insertComm(1,scope.row.commId,scope.row.commName,scope.row.commSort,scope.row.commCateId,scope.row.commPartId,scope.row.commPrice)" >修改</el-button>
            <el-button type="danger" plain @click="offShelfs(scope.row.commId)">下架</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="base_pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>

    </div>

    <!--    增加商品弹窗-->
    <div class="base_dialog">
      <el-dialog
        :modal="false"
        :title="text"
        :visible.sync="commDialog"
        width="100vh"
        style="margin-top: -13vh"
        @close="CloseCommodityDialog">
        <el-form ref="form" :model="commForm" label-width="80px">
          <el-form-item label="商品名称">
            <el-input v-model="commForm.commName"></el-input>
          </el-form-item>
          <el-form-item label="商品分类">
            <el-select v-model="commForm.commCateId" clearable filterable placeholder="请选择商品分类">
              <el-option v-for="item in commodityInformationCateGory"
                         :key="item.cateId" :label="item.cateName" :value="item.cateId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品价格">
            <el-input v-model="commForm.commPrice"></el-input>
          </el-form-item>
          <el-form-item label="商品排序">
            <el-input v-model="commForm.commSort" type="number"
                      onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"></el-input>
          </el-form-item>
          <el-form-item label="商品图片">
            <input accept="image/*" name="upimage" @change="upload" id="upload_file" type="file" multiple="true">
          </el-form-item>
          <el-form-item label="商品部件">
            <el-transfer
              v-model="commForm.commPartId"
              :data="commodityInformationCateGoryData"
              :titles="['商品部件', '已选部件']"
              filterable
              filter-placeholder="请输入部件名称"></el-transfer>
          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="CloseCommodityDialog">取 消</el-button>
          <el-button v-if="dialogFlag == 0" type="primary" @click="modifyAndInsertCommodity(commForm)">确 定</el-button>
          <el-button v-else type="primary" @click="modifyAndInsertCommodity(commForm)">修 改</el-button>
        </span>
      </el-dialog>
    </div>


  </div>
</template>

<script>
import {selectCateGoryName} from '@/api/classificationManagement/cateGory'
import { selectCommodity, uploadCommodityImage, modifyInsertCommodity, offShelf } from '@/api/commodityManagement/commodityInformation'
import {Message} from "element-ui";

export default {
  name: 'commodityInformation',

  data() {
    return{
      uploadImageNumber: 0,
      fits: ['fill'],
      imageURL: 'http://124.70.208.221/api/cateGory/selectImage/',
      commForm:{
        commId:'',
        commName:'',
        commSort:0,
        commCateId: '',
        commImage: '',
        commPartId:[],
        commPrice: 0
      },
      params: [],
      form: {
        img: ""
      },
      text: '',
      dialogFlag: 0,
      commDialog: false,
      cateId: '',
      commodityInformationSearch: '',
      commodityInformationData: [],
      commodityInformationCateGory: [],
      commodityInformationCateGoryData: [],
      value:[],
      SelectValue: '',
      pageSize: 10,
      pageNum: 1,
      total: 0,
    }
  },

  mounted () {
    // this.selectCommodityList();
    this.selectCateGoryAllName();
    this.selectCommodityList();
  },

  methods:{

    //上传图片
    upload (e) {
      console.log(e)
      // let file = e.target.files[0];
      // let param = new FormData();

      for(let i = 0; i < e.target.files.length; i++){
        let param = new FormData();
        param.append("file", e.target.files[i]);
        console.log(param)
        this.params[i]=param;
      }
      //通过append向form对象添加数据
      // param.append("file",file);
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'//必须要指定
        }
      };
      // this.params = param;
      console.log(this.params)
    },

    modifyAndInsertCommodity(commForm){
      console.log(commForm)
      if(commForm.commName == null){
        Message.error("商品名称不能为空！");
      }else if(commForm.commSort < 0){
        Message.error("商品排序不能小于0！");
      }else if(commForm.commPrice < 0){
        Message.error("商品价格不能小于0！");
      }else if(commForm.commPrice == null){
        Message.error("商品价格不能为空！");
      }else if(commForm.commName == ''){
        Message.error("商品名称不能为空！");
      }else{
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'//必须要指定
          }
        };
        // let commPartIdStr = commForm.commPartId;
        commForm.commPartId = commForm.commPartId.join(",");

        if (this.dialogFlag == 1){
          if (this.params == ""){
            this.updateCommodity(commForm);
          }else{
            this.imageAndInsertAndUpdate(commForm,config);
          }
        }else{
          this.imageAndInsertAndUpdate(commForm,config);
        }

      }
    },

    imageAndInsertAndUpdate(commForm,config){
      let count = 0;
      for(let i = 0; i < this.params.length; i++){

        uploadCommodityImage(this.params[i],config).then(response => {
          this.form.img = response.data;
          this.commForm.commImage = this.commForm.commImage + "," + response.data.name;
          console.log(this.commForm.commImage)
          count += 1;
          console.log(count);
          if(count == this.params.length){
            console.log("submit")
            this.commForm.commImage = this.commForm.commImage.substring(1,this.commForm.commImage.length);
            modifyInsertCommodity(commForm).then(res => {
              if(res.data == 0){
                if(this.dialogFlag == 1){
                  Message.success("修改成功！");
                }else{
                  Message.success("增加成功！");
                }
                this.CloseCommodityDialog();
                this.selectCommodityList();
              }
            });
          }
        });
      }
      console.log(count)


    },
    updateCommodity(commForm){
      modifyInsertCommodity(commForm).then(res => {
        if(res.data == 0){
          if(this.dialogFlag == 1){
            Message.success("修改成功！");
          }else{
            Message.success("增加成功！");
          }
          this.CloseCommodityDialog();
          this.selectCommodityList();
        }
      });
    },

    //下架商品
    offShelfs(id){
      let data = {
        commId: id,
        flag: 0
      }
      offShelf(data).then(res => {
        if(res.data == 0){
          Message.success("下架成功！");
        }else{
          Message.success("下架失败！");
        }
        this.selectCommodityList();
      })
    },


    insertComm(flag,id,name,sort,cateId,commPartId, commPrice){
      this.commDialog = true;
      if(flag == 0){
        this.dialogFlag = 0;
        this.text = '新增商品';
      }else{
        this.dialogFlag = 1;
        this.text = '修改商品';
      }
      console.log(sort)
      this.commForm.commPrice = commPrice;
      this.commForm.commId = id;
      this.commForm.commSort = sort;
      this.commForm.commName = name;
      this.commForm.commCateId = cateId;
      let commPartIdList = commPartId.split(",");
      this.commForm.commPartId = commPartIdList;
      this.commDialog = true;
    },

    //关闭弹窗
    CloseCommodityDialog(){
      this.commForm.cateName = '';
      this.commForm.cateSort = '';
      this.commForm.commImage = '';
      this.commForm.commPartId = [];
      this.commDialog = false;

    },

    //查询所有分类名称
    selectCateGoryAllName(){
      selectCateGoryName().then(res => {
        this.commodityInformationCateGory = res.data;
      });
    },



    selectCommodityList(){
      let data = {
        pageSize : this.pageSize,
        pageNum: this.pageNum,
        commodityInformationSearch: this.commodityInformationSearch,
        cateId: this.cateId,
        flag: 0
      }
      selectCommodity(data).then(res => {
        let resData = res.data;
        this.commodityInformationData = resData.records;
        this.total = resData.page.total;
        this.commodityInformationCateGoryData = [];
        for (let i = 0; i <resData.newRecords.length; i++){
          if(resData.newRecords[i].commFlag == 1){
            this.commodityInformationCateGoryData.push({
              key: resData.newRecords[i].commId,
              label: resData.newRecords[i].commName
            })
          }

        }
      });
    },

    clearSearch(){
      this.commodityInformationSearch = '';
      this.SelectValue = '';
    },

    //获取选中分类id
    clickSelect(id){
      this.cateId = id;
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.selectCommodityList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.selectCommodityList();
    },

    //表格序号排序
    indexMethod(index) {
      return index + 1;
    }
  }
}
</script>

<style lang="less" scoped>

</style>
