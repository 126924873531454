import request from "@/api/request";

export function selectCarouselMap(data) {
  return request({
    url: '/carousel/selectCarouselMap',
    method: 'get',
    params: data
  })
}

export function deleteCarouselImage(data) {
  return request({
    url: '/carousel/deleteCarouselImage',
    method: 'get',
    params: data
  })
}

export function uploadCarouselImage(obj) {
  return request({
    url: '/carousel/uploadCarouselImage',
    method: 'post',
    data: obj
  })
}

export function modifyInsertCarousel(obj) {
  return request({
    url: '/carousel/modifyInsertCarousel',
    method: 'post',
    data: obj
  })
}
