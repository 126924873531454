<template>
  <div class="base_overall">
<!--    分类管理头部区域-->
    <div class="base_header">
      <el-input class="base_search" placeholder="请输入搜索内容" prefix-icon="el-icon-search" v-model="cateGorySearch" clearable></el-input>

      <el-button type="primary" icon="el-icon-search" circle style="margin-left: 1%" @click="selectCateGoryList"></el-button>
      <el-button type="danger" icon="el-icon-delete" circle @click="closeCateGorySearch"></el-button>
      <el-button type="primary" plain class="insert_base_button" @click="insertCateGory(0)">增加分类</el-button>
    </div>
<!--    分类管理主体表单区域-->
    <div class="base_main">
      <el-table class="base_table" :data="cateGoryData"
                stripe empty-text="暂时还没有分类哦！" border height="72vh" >
        <el-table-column type="index" :index="indexMethod" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="cateId" label="分类编号" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="cateImage" label="分类图片" align="center" :show-overflow-tooltip="true">

          <template slot-scope="scope">
            <div class="block" v-for="fit in fits" :key="fit">
              <el-image
                :fit="fit"
                style="width: 30px; height: 30px"
                :src="imageURL+scope.row.cateImage"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cateName" label="分类名称" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column sortable prop="cateSort" label="排序" align="center"></el-table-column>
        <el-table-column sortable prop="cateModificationTime" label="修改时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="280">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="insertCateGory(1,scope.row.cateId,scope.row.cateName,scope.row.cateSort)">修改</el-button>
            <el-button type="danger" plain @click="offShelfs(scope.row.cateId)">下架</el-button>
          </template>
        </el-table-column>
      </el-table>
        <el-pagination
          class="base_pagination"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
    </div>

<!--    增加分类弹窗-->
    <div class="base_dialog">
      <el-dialog
        :modal="false"
        :title="text"
        :visible.sync="cateGoryDialog"
        width="30%"
        @close="CloseCateGoryDialog">
        <el-form ref="form" :model="cateForm" label-width="80px">
          <el-form-item label="分类名称">
            <el-input v-model="cateForm.cateName"></el-input>
          </el-form-item>
          <el-form-item label="分类排序">
            <el-input v-model="cateForm.cateSort" type="number"
                      onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"></el-input>
          </el-form-item>
          <el-form-item label="分类图片">
            <input accept="image/*" name="upimage" @change="upload" id="upload_file" type="file">
          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="CloseCateGoryDialog">取 消</el-button>
          <el-button v-if="dialogFlag == 0" type="primary" @click="modifyAndInsertCateGory(cateForm)">确 定</el-button>
          <el-button v-else type="primary" @click="modifyAndInsertCateGory(cateForm)">修 改</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  modifyInsertCateGory,
  selectCateGory,
  offShelf,
  deleteCateGoryImage, uploadCateGoryImage
} from '@/api/classificationManagement/cateGory'
import { Message } from 'element-ui'
import {modifyInsertCommodity, uploadCommodityImage} from "@/api/commodityManagement/commodityInformation";

export default {
  name: 'cateGory',

  data() {
    return{
      fits: ['fill'],
      imageURL: 'http://124.70.208.221/api/cateGory/selectImage/',
      text:'',
      params: '',
      config: '',
      form: {
        img: ""
      },
      dialogImageUrl: '',
      dialogVisible: false,
      modifyId: '',
      dialogFlag: 1,
      pageSize: 10,
      pageNum: 1,
      total: 0,
      cateGoryDialog: false,
      cateGorySearch: '',
      cateGoryData: [],
      cateForm: {
        cateId : '',
        cateName: '',
        cateSort: 0,
        cateImage: '',
      },
    }
  },

  mounted () {
    this.selectCateGoryList();
  },

  methods:{

    //上传图片
    upload (e) {
      console.log(e)
      let file = e.target.files[0];
      let param = new FormData();
      //通过append向form对象添加数据
      param.append("file", file);
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'//必须要指定
        }
      };
      this.params = param;
      console.log(param)
    },

    //下架分类
    offShelfs(id){
      let data = {
        cateId: id,
        flag: 0
      }
      offShelf(data).then(res => {
        if(res.data == 0){
          Message.success("下架成功！");
        }else{
          Message.success("下架失败！");
        }
        this.selectCateGoryList();
      })
    },

    //新增修改分类
    modifyAndInsertCateGory(cateForm){
      if(cateForm.cateName == null){
        Message.error("分类名称不能为空！");
      }else if(cateForm.cateSort < 0){
        Message.error("分类排序不能小于0！");
      }else if(cateForm.cateName == ''){
        Message.error("分类名称不能为空！");
      }else{
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'//必须要指定
          }
        };
        if (this.dialogFlag == 1){
          if (this.params == ""){
            this.updateCommodity(cateForm);
          }else{
            this.imageAndInsertAndUpdate(cateForm,config);
          }
        }else{
          this.imageAndInsertAndUpdate(cateForm,config);
        }

      }
    },

    imageAndInsertAndUpdate(cateForm,config){
      uploadCateGoryImage(this.params,config).then(response => {
        this.form.img = response.data;
        this.cateForm.cateImage = response.data.name;
        modifyInsertCateGory(cateForm).then(res => {
          if(res.data == 0){
            if(this.dialogFlag == 1){
              Message.success("修改成功！");
            }else{
              Message.success("增加成功！");
            }
            this.CloseCateGoryDialog();
            this.selectCateGoryList();
          }else{
              if(this.dialogFlag == 1){
                  Message.error("修改失败！");
              }else{
                  Message.error("增加失败！");
              }
          }
        });
      });
    },
    updateCommodity(cateForm){
      modifyInsertCateGory(cateForm).then(res => {
        if(res.data == 0){
          if(this.dialogFlag == 1){
            Message.success("修改成功！");
          }else{
            Message.success("增加成功！");
          }
          this.CloseCateGoryDialog();
          this.selectCateGoryList();
        }else{
            if(this.dialogFlag == 1){
                Message.error("修改失败！");
            }else{
                Message.error("增加失败！");
            }
        }
      });
    },

    //清空搜索框
    closeCateGorySearch(){
      this.cateGorySearch = '';
    },

    //分页查询分类的数据（带模糊查询）
    selectCateGoryList(){
      let data = {
        pageSize : this.pageSize,
        pageNum: this.pageNum,
        cateGorySearch: this.cateGorySearch,
        flag: 0
      }
      selectCateGory(data).then(res => {
        let resData = res.data;
        this.cateGoryData = resData.records;
        this.total = resData.total;
      });
    },


    //增加修改分类
    insertCateGory(flag,id,name,sort){
      if(flag == 0){
        this.dialogFlag = 0;
        this.text = '新增分类';
      }else{
        this.dialogFlag = 1;
        this.text = '修改分类';
      }
      this.modifyId = id;
      this.cateForm.cateId = id;
      this.cateForm.cateSort = sort;
      this.cateForm.cateName = name;
      this.cateGoryDialog = true;
    },
    //关闭弹窗
    CloseCateGoryDialog(){
      this.cateForm.cateName = '';
      this.cateForm.cateSort = '';
      this.cateGoryDialog = false;

    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.selectCateGoryList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.selectCateGoryList();
    },
    //表格序号排序
    indexMethod(index) {
      return index + 1;
    }
  }
}
</script>

<style lang="less" scoped>

@import "src/static/css/ClassificationManagement/cateGory.less";
</style>
