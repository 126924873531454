import request from "@/api/request";
import {forEach} from "core-js/stable/dom-collections";

export function selectCommodity(data) {
  return request({
    url: '/commodity/selectCommodity',
    method: 'get',
    params: data
  })
}

export function uploadCommodityImage(obj) {
  return request({
    url: '/commodity/uploadCommodityImage',
    method: 'post',
    data: obj
  })
}
function updateImageList(list){
  let req = request({
    url: '/commodity/uploadCommodityImage',
    method: 'post'
  })
  console.log(req);


}


export function modifyInsertCommodity(obj) {
  return request({
    url: '/commodity/modifyInsertCommodity',
    method: 'post',
    data: obj
  })
}

export function offShelf(data) {
  return request({
    url: '/commodity/offShelf',
    method: 'get',
    params: data
  })
}
