import { render, staticRenderFns } from "./wel.vue?vue&type=template&id=184d8372&scoped=true"
import script from "./wel.vue?vue&type=script&lang=js"
export * from "./wel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "184d8372",
  null
  
)

export default component.exports