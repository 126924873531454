import request from "@/api/request";

export function postLogin(obj) {
  return request({
    url: '/admin/postLogin',
    method: 'post',
    data: obj
  })
}

