<template>
  <div class="base_overall">
<!--    用户管理页面头部区域-->
    <div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style lang="less" scoped>
//@import "src/static/css/base.less";
@import "src/static/css/userManage/userManage.less";
</style>
