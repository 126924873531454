<template>

  <div>
    <div v-for="i in aaa">
      <div>{{item}}</div>
    </div>
  </div>

</template>

<script>
  export default {

    data(){
      return {
        aaa: 540
      }
    }

  }

</script>

<style>


</style>
