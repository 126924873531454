<template>
  <div>
    <h3>欢迎使用美家居窗帘后台管理系统</h3>
  </div>
</template>

<script>
export default {
  name: 'wel'
}
</script>

<style scoped>

</style>
