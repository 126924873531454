<template>
  <div class="login_container">
    <div class="login_box">
<!--      头像区域-->
      <div class="avater_box">
        <img :src="require('/src/assets/logo.png')">
      </div>
<!--登录表单区域-->
      <el-form label-width="100px" class="login_form" ref="adminLoginRef">
        <el-form-item label="管理员账号：">
          <el-input prefix-icon="el-icon-user" v-model="adminLogin.userAdminName"></el-input>
        </el-form-item>
        <el-form-item label="管理员密码：">
          <el-input prefix-icon="el-icon-lock" type="password" v-model="adminLogin.userAdminPwd"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" round size="medium" @click="login">登录</el-button>
          <el-button round size="medium" @click="closeLogin">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>

<script>
import {postLogin} from '@/api/admin/login';
import {Message} from 'element-ui';
import Cookie from 'js-cookie'

export default {
  data(){
    return{
      adminLogin: {
        userAdminName: '',
        userAdminPwd: ''
      }
    }
  },

  methods: {
    //登录验证
    login(){
      if(this.adminLogin.username == "" && this.adminLogin.password == ""){
        Message.error("管理员账号和密码不能为空！");
      }else if(this.adminLogin.username == ""){
        Message.error("请输入管理员账号！");
      }else if(this.adminLogin.password == ""){
        Message.error("请输入管理员密码！");
      }else{
        //登录请求
        postLogin(this.adminLogin).then(res =>{
          let data = res.data;
          if(data.result == "失败"){
            Message.error("账号或密码错误！");
          }else if(data.result == "成功"){
            Cookie.set("token",data.token);
            this.$router.push("/web/system/wel");
            Message.success("登录成功！");
          }
        });
        // this.$router.push("/index");
        // Message.success("登录成功！");
      }

    },

    //清空登录信息
    closeLogin(){
      this.adminLogin.username = '';
      this.adminLogin.password = '';
    }
  }
}
</script>

<style lang="less" scoped>
@import "src/static/css/login/login.less";
</style>
