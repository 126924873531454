<template>
  <div class="base_overall">
    <!--    分类管理头部区域-->
    <div class="base_header">
      <el-input class="base_search" placeholder="请输入搜索内容" prefix-icon="el-icon-search" v-model="cateGorySearch" clearable></el-input>

      <el-button type="primary" icon="el-icon-search" circle style="margin-left: 1%"></el-button>
      <el-button type="danger" icon="el-icon-delete" circle></el-button>
      <el-button type="primary" plain class="insert_base_button">订单统计</el-button>
    </div>
    <!--    分类管理主体表单区域-->
    <div class="base_main">
      <el-table class="base_table" :data="cateGoryData" stripe empty-text="暂时还没有订单哦！" border>
        <el-table-column type="index" :index="indexMethod" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="name" label="订单编号" align="center"></el-table-column>
        <el-table-column prop="name" label="商品编号" align="center"></el-table-column>
        <el-table-column prop="name" label="用户编号" align="center"></el-table-column>
        <el-table-column prop="name" label="物流编号" align="center"></el-table-column>
        <el-table-column prop="name" label="订单属性" align="center"></el-table-column>
        <el-table-column prop="name" label="商品图片" align="center"></el-table-column>
        <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
        <el-table-column prop="name" label="商品分类" align="center"></el-table-column>
        <el-table-column prop="name" label="商品价格" align="center"></el-table-column>
        <el-table-column prop="name" label="订单运费" align="center"></el-table-column>
        <el-table-column prop="name" label="收货地址" align="center"></el-table-column>
        <el-table-column prop="name" label="订单备注" align="center"></el-table-column>
        <el-table-column prop="address" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="address" label="完成时间" align="center"></el-table-column>
        <el-table-column prop="name" label="用户评价" align="center"></el-table-column>
        <el-table-column prop="name" label="订单状态" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="160"></el-table-column>
      </el-table>
      <el-pagination
        class="base_pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="40">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orderManagement',

  data() {
    return{
      cateGorySearch: '',
      cateGoryData: [],
      currentPage4: 1,
    }
  },

  methods:{
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    //表格序号排序
    indexMethod(index) {
      return index + 1;
    }
  }
}
</script>

<style lang="less" scoped>

</style>
